import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import { styles } from '../../../styles';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../Common/BackButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { getCustomRoute, helpers } from '../../../actions';
import * as routes from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { getAffinity } from '../../../actions/getCustomRoute';
import { relation } from '../../../constants/affinityAndSubgroup';
import { fetchAccountInfo } from '../../../actions/db';

const styleOverride = {
  affinity: {
    '& span.MuiTypography-body1': {
      fontWeight: '400',
    },
  },
};

const Affinity = () => {
  // Get needed Redux values.
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const staticText = useSelector((state) => state.textsState.data);

  const customStyles = { ...styles.defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  // Const affinity array.
  const affinityTypes = [
    {
      key: 'veteran',
      text: 'AffinityVeteran',
    },
    {
      key: 'active_duty',
      text: 'AffinityActive',
    },
    {
      key: 'academy',
      text: 'AffinityAcademy',
    },
    {
      key: 'commissioned',
      text: 'AffinityCommissioned',
    },
    {
      key: 'relative',
      text: 'AffinityRelative',
    },
  ];

  const existingAffinity = getAffinity(accountData, 'military');

  const nonReverifiedMessage =
    staticText.SameAffinityMessage ??
    'Please update your military status to continue.';
  const sameAffinityMessage =
    staticText.SameAffinityMessage ??
    'Please confirm your military status for reverification.';
  const reverificationRestricted =
    staticText.ReverificationRestricted ?? 'Reverification not yet available.';
  const affinityChangeWarning =
    staticText.AffinityChangeWarning ?? 'Verification required.';
  const activeDutyToVeteran =
    staticText.ActiveDutyToVeteran ??
    'Transition from Active Duty to Veteran status allowed without verification.';

  // Prepare state variables.
  const [affinity, setAffinity] = useState(existingAffinity);
  const [accountDataSynced, setAccountDataSynced] = useState(false);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(!existingAffinity);
  const [disableNextStep, setDisableNextStep] = useState(false);
  const reverifiedAffinities = ['active_duty', 'academy', 'commissioned'];
  const nonReverifiedAffinities = ['veteran', 'relative'];

  const getDefaultMessage = () => {
    setMessageError(false);
    if (reverifiedAffinities.includes(existingAffinity)) {
      const militaryInfo = accountData?.identification?.military;
      // Check for reverification.
      if (militaryInfo?.expiration) {
        const oneDay = 1000 * 60 * 60 * 24;
        const militaryExpirationTime = new Date(militaryInfo.expiration);
        const currentTime = new Date();
        const daysDifference = Math.ceil(
          (militaryExpirationTime.getTime() - currentTime.getTime()) / oneDay
        );
        if (militaryInfo?.expiration && daysDifference <= 30) {
          setMessage(sameAffinityMessage);
        } else {
          setDisableNextStep(true);
          setMessage(reverificationRestricted);
        }
      } else {
        setMessage(sameAffinityMessage);
      }
    } else if (nonReverifiedAffinities.includes(existingAffinity)) {
      setMessage(nonReverifiedMessage);
      setDisableNextStep(true);
    }
  };

  /**
   * Change affinity helper.
   *
   * @param event
   */
  const changeAffinity = (event) => {
    if (
      accountData?.flags?.reverify &&
      accountData?.flags?.reverify !== 'verified'
    ) {
      // Set corresponding reverification message.
      if (existingAffinity === event.target.value) {
        getDefaultMessage();
      } else if (
        // Allow  active_duty to veteran transition without verification.
        existingAffinity === 'active_duty' &&
        event.target.value === 'veteran' &&
        accountData?.identification?.military?.status === 'verified'
      ) {
        setMessageError(false);
        setMessage(activeDutyToVeteran);
      } else if (
        // If affinity already exists and user is verified,
        // then we show new verification warning when it is changed.
        existingAffinity &&
        existingAffinity !== event.target.value
      ) {
        setMessageError(true);
        setMessage(affinityChangeWarning);
      } else {
        setMessage('');
      }

      // Allow transition if affinity was changed.
      if (existingAffinity !== event.target.value) {
        setDisableNextStep(false);
      }
    }

    setAffinity(event.target.value);
  };

  useEffect(() => {
    getDefaultMessage();
  }, []);

  useEffect(() => {
    if (accountData && accountDataSynced) {
      // If user converted from active duty to former
      // then we do not proceed with verification.
      const currentAffinity = getAffinity(accountData, 'military');
      if (currentAffinity === 'veteran') {
        getCustomRoute(history);
      }
    }
  }, [accountData, accountDataSynced]);

  /**
   * Next step helper.
   */
  const nextStep = () => {
    setDisableNextStep(true);

    helpers
      .setAffinityAndSubgroup(relation[affinity])
      .then(() => {
        dispatch(fetchAccountInfo(authUser?.uid));
      })
      .then(() => {
        if (existingAffinity === 'active_duty' && affinity === 'veteran') {
          setAccountDataSynced(true);
        } else {
          // Direct user to the next verification step.
          history.push(routes.AFFIDAVIT);
        }
      })
      .catch((error) => {
        helpers.handleError(error, dispatch);
      })
      .finally(() => {
        setDisableNextStep(false);
      });
  };

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box}
    >
      <BackButton disabled={disableNextStep} />

      <div className={'header'}>
        <Typography variant="h1" component="h1">
          {staticText.MilitaryCredentialsTitle}
        </Typography>
        <Typography variant="h2" component="h2">
          {staticText.MilitaryCredentialsSubTitle}
        </Typography>
      </div>

      <div className={classes.centerContent}>
        <FormControl component="fieldset" className={classes.affinity}>
          {message && (
            <FormHelperText
              error={messageError}
              className={classes.marginHelper}
            >
              {message}
            </FormHelperText>
          )}
          <RadioGroup
            aria-label="affinity"
            name="affinity"
            defaultValue={affinity}
            value={affinity}
            onChange={changeAffinity}
          >
            {authUser &&
              affinityTypes.map((affinityType) => (
                <FormControlLabel
                  key={affinityType.key}
                  control={
                    <Radio
                      color="secondary"
                      inputProps={{
                        'data-id':
                          'military-credentials-affinity-' +
                          affinityType.key.replace('_', '-'),
                      }}
                    />
                  }
                  checked={affinity === affinityType.key}
                  editable={'true'}
                  value={affinityType.key}
                  label={staticText[affinityType.text]}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </div>
      <div className={'footer'}>
        <Button
          className={classes.button}
          type="submit"
          disableElevation
          onClick={nextStep}
          disabled={!affinity || disableNextStep}
          fullWidth
          size="large"
          data-id="military-credentials-next"
        >
          {staticText?.MilitaryCredentialsBtn
            ? staticText?.MilitaryCredentialsBtn
            : 'Next Step'}
        </Button>
      </div>
    </Box>
  );
};

export default Affinity;

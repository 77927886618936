import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { helpers } from '../../../actions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styles } from '../../../styles';
import getCustomRoute, {
  getAllIdentificationStatuses,
} from '../../../actions/getCustomRoute';
import { addAccountInfo } from '../../../actions/helpers';

const Verify = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const accountData = useSelector((state) => state.accountState.account);
  const authUser = useSelector((state) => state.sessionState.authUser);
  const sessionDB = useSelector((state) => state.sessionDBState);
  const configData = useSelector((state) => state.configState.config);
  const staticText = useSelector((state) => state.textsState.data);

  const useStyles = makeStyles(() => styles.defaultFormStyles);
  const editMode = useSelector(
    (state) => state.sessionDBState.data.urlQuery.edit
  );
  const classes = useStyles();

  const [initiateFinish, setInitiateFinish] = useState(false);
  const [initiateVerification, setInitiateVerification] = useState(false);

  useEffect(() => {
    if (editMode) {
      finishLogin();
    } else {
      setInitiateVerification(true);
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (accountData?.flags?.reverify === 'verified') {
      addAccountInfo(
        {
          flags: {
            reverify: false,
          },
        },
        authUser.uid
      ).catch((error) => {
        helpers.handleError(error, dispatch);
      });
    }
  }, [authUser]);

  useEffect(() => {
    // Show thank you page only if a new verification was initialized
    if (initiateVerification) {
      const { identityStatus, militaryStatus } =
        getAllIdentificationStatuses(accountData);
      if (identityStatus === 'verified' && militaryStatus === 'verified') {
        finishLogin();
      }
      // Show Manual Verification page if failed.
      else if (identityStatus !== 'verified' || militaryStatus !== 'verified') {
        getCustomRoute(history);
      }
    }
    // eslint-disable-next-line
  }, [accountData, initiateVerification])

  const redirectTo = (redirectUrl) => {
    setTimeout(() => (window.location.href = redirectUrl), 3000);
  };

  const finishLogin = () => {
    setInitiateFinish(true);
    if (sessionDB.data.returnType === 'widget') {
      // Retrieve authenticated user access token.
      redirectTo(decodeURIComponent(sessionDB.data.urlQuery.return_to));
    } else {
      let returnUrlData = sessionDB.data.urlQuery.return_to
        ? decodeURIComponent(sessionDB.data.urlQuery.return_to)
        : configData.platformUrl;
      redirectTo(returnUrlData);
    }
  };

  return (
    <Box
      p={{ xs: 2, md: 10 }}
      boxShadow={{ xs: 0, sm: 0, md: 6 }}
      className={classes.box + ' ' + classes.boxHeigher}
    >
      {initiateFinish && (
        <React.Fragment>
          <Typography variant="h1" component="h1">
            {staticText.verifyTitle}
          </Typography>
          {!editMode && (
            <Typography variant="h2" component="h2">
              {staticText.verifySubTitle}
            </Typography>
          )}
          <div className={classes.contentAbsoluteCenter}>
            <CheckCircleIcon className={classes.counterIcon}></CheckCircleIcon>
            <Typography className={classes.counter} component="p">
              {staticText.verifyBody}
              <span>{staticText.verifyCount}</span>
            </Typography>
          </div>
        </React.Fragment>
      )}
    </Box>
  );
};

export default Verify;
